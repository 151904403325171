.TopBar {
    font-family: "SegoeUI-SemiBold-final", "Segoe UI Semibold", "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)", Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica, Arial, sans-serif;
    height: 48px;
}

.TopBar .WaffleIcon {
    height: 48px;
    width: 48px;
    margin-left: -50px;
    text-align: center;
    float:left;
}

.TopBar .WaffleIcon:hover {
    background:#dbdad7;
}

.TopBar .TopBarLink {
    padding-top: 22px;
    vertical-align: middle;
}

.TopBarRow {
    margin-left: -45px;
}

.AppListButton {
    background: white;
}

.AppListButton:hover {
    background: white;
}
