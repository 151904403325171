//@import '../../../common/common';
@import 'office-ui-fabric-react/dist/sass/variables/_Color.Variables.scss';
@import 'office-ui-fabric-react/dist/sass/variables/_Font.Variables.scss';

.AddCatalogItemDlgContainer {
    height: 80vh;
    width: 80vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}
  
.AddCatalogItemDlgBody {
    flex: 4 4 auto;
    padding: 5px 28px;
    overflow-y: hidden;
}

.AddCatalogItemDlgHeader {
    flex: 1 1 auto;
    background: $ms-color-themePrimary;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: $ms-font-size-xxl;
    font-weight: $ms-font-weight-semibold;
    padding: 0 28px;
    min-height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
}
