@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,700);
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: #0061AA;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
}

i {
  padding-right: 5px;
}

ul {
  list-style-type: none;
  font-family: "Lucida Console", Monaco, monospace;
  color: #333;
  font-size: smaller;
  white-space: nowrap;
  margin-bottom: 2px;
}

pre {
  font-family: "Lucida Console", Monaco, monospace;
  color: #333;
  font-size: small;
  margin-bottom: 6px;
}

iframe {
  border: 1px !important;
  border-width: 1px !important;
  border-style: inset !important;
}

.ms-Grid-row {
  margin-bottom: 6px !important;
}

.UserData {
  width: 80%;
}

.highlight-info {
  color: yellow;
}
.highlight-debug {
  color: #aaa;
}
.highlight-error {
  color: red;
  font-weight: bold;
}
.logtable {
  font-family: Menlo,Consolas,monospace;
  white-space: pre;
  font-size: normal;
  background-color: black;
  color: #eee;
}

.App {
  min-height: 100vh; 
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.App .body {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.body .sidebar {
  order: -1;
}

.body .content {
  flex: 1 1;
}

.body .sidebar {
  max-width: 12em;
  flex: 0 0 12em;
}

.App .header {
  height: 4em;
}

/* 
Set the main wrapper component to 
take up the entire height of the page
 */
.App {
  min-height: 100vh; 
}
/*
To use Flexbox we need to set the display
setting of the container component to flex.
The we set the direction of the wrapped 
components. By default flexbox will order 
them by row, but we can also tell it to do
it by column.
*/
.App {
  display: flex;
  flex-direction: column;
}
/*
Something similar is done to the body 
element, except we order the wrapped 
component as rows.
*/
.App .body {
  display: flex;
  flex-direction: row;
}
/*
Flexbox let's us reorder the way the 
elements are placed inside the wrapper
component. Using the order rule, we move
the sidebar to the left of the content 
component. We also set its width to 12em
and set its flex propertires so it doesn't
grow or shrink with the rest of the page.
*/
.body .sidebar {
  order: -1;
  max-width: 14em;
  flex-grow: 0;    /* Defined the ability to groe */
  flex-shrink: 0;  /* Defines the ability to shrink */
  flex-basis: 14em;/* Default value of the element before distribuing the remaing space */
}
/*
Lastly we give some custom height to our 
header and footer components.
*/
.App .header,
.App .footer {
  height: 4em;
}

.TopBar {
    font-family: "SegoeUI-SemiBold-final", "Segoe UI Semibold", "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)", Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica, Arial, sans-serif;
    height: 48px;
}

.TopBar .WaffleIcon {
    height: 48px;
    width: 48px;
    margin-left: -50px;
    text-align: center;
    float:left;
}

.TopBar .WaffleIcon:hover {
    background:#dbdad7;
}

.TopBar .TopBarLink {
    padding-top: 22px;
    vertical-align: middle;
}

.TopBarRow {
    margin-left: -45px;
}

.AppListButton {
    background: white;
}

.AppListButton:hover {
    background: white;
}

.NavBar {
  font-family: "SegoeUI-SemiBold-final", "Segoe UI Semibold", "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)", Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica, Arial, sans-serif;
  height: 48px;
  color: white;
  background-color: black;
}

.NavBar .NavBarIcon {
  background-color: #0061AA;
  color: white;
  height: 48px;
  width: 48px;
  margin-top: -5px;
  margin-left: -8px;
  text-align: center;
  float:left;
}

.NavBar .NavBarIcon:hover {
  background:#0078d7;
}

.NavBar .NavBarTitle {
  padding-left: 8px;
  height: 48px;
  float:left;
  vertical-align: middle;
  font-size: 16px;
}

.NavBarTitle span {
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}

.NavBar .NavBarPersonaIcon {
  float:right;
  height: 48px;
}

.AddCatalogItemDlgContainer {
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch; }

.AddCatalogItemDlgBody {
  flex: 4 4 auto;
  padding: 5px 28px;
  overflow-y: hidden; }

.AddCatalogItemDlgHeader {
  flex: 1 1 auto;
  background: #0078d4;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
  padding: 0 28px;
  min-height: 40px;
  padding-top: 10px;
  padding-bottom: 10px; }

.UserData {
    max-height: 20cm;
    max-width: 30cm;
}

/*
CodeMirror style
*/
.CodeMirror {
    border: 1px solid #eee;
    height: 100%;
}

