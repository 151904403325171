@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,700");
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: #0061AA;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
}

i {
  padding-right: 5px;
}

ul {
  list-style-type: none;
  font-family: "Lucida Console", Monaco, monospace;
  color: #333;
  font-size: smaller;
  white-space: nowrap;
  margin-bottom: 2px;
}

pre {
  font-family: "Lucida Console", Monaco, monospace;
  color: #333;
  font-size: small;
  margin-bottom: 6px;
}

iframe {
  border: 1px !important;
  border-width: 1px !important;
  border-style: inset !important;
}

.ms-Grid-row {
  margin-bottom: 6px !important;
}

.UserData {
  width: 80%;
}

.highlight-info {
  color: yellow;
}
.highlight-debug {
  color: #aaa;
}
.highlight-error {
  color: red;
  font-weight: bold;
}
.logtable {
  font-family: Menlo,Consolas,monospace;
  white-space: pre;
  font-size: normal;
  background-color: black;
  color: #eee;
}

.App {
  min-height: 100vh; 
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App .body {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.body .sidebar {
  order: -1;
}

.body .content {
  flex: 1;
}

.body .sidebar {
  max-width: 12em;
  flex: 0 0 12em;
}

.App .header {
  height: 4em;
}
