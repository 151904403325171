.NavBar {
  font-family: "SegoeUI-SemiBold-final", "Segoe UI Semibold", "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)", Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica, Arial, sans-serif;
  height: 48px;
  color: white;
  background-color: black;
}

.NavBar .NavBarIcon {
  background-color: #0061AA;
  color: white;
  height: 48px;
  width: 48px;
  margin-top: -5px;
  margin-left: -8px;
  text-align: center;
  float:left;
}

.NavBar .NavBarIcon:hover {
  background:#0078d7;
}

.NavBar .NavBarTitle {
  padding-left: 8px;
  height: 48px;
  float:left;
  vertical-align: middle;
  font-size: 16px;
}

.NavBarTitle span {
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}

.NavBar .NavBarPersonaIcon {
  float:right;
  height: 48px;
}
